"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyPagination = exports.keyPaginationFallbackQueryParams = exports.keyPaginationDefaultQueryParams = void 0;
const SimplePagination_1 = require("@ui/SimplePagination");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
exports.keyPaginationDefaultQueryParams = {
    count: 20,
    key: '',
    paginationDirection: sonar_ts_types_1.PaginationDirection.Forward
};
exports.keyPaginationFallbackQueryParams = {
    key: '',
    paginationDirection: sonar_ts_types_1.PaginationDirection.Forward
};
const KeyPagination = (_a) => {
    var _b;
    var { listRef, resource, onChange, showSizeChanger } = _a, rest = __rest(_a, ["listRef", "resource", "onChange", "showSizeChanger"]);
    const handlePaginationChange = (0, react_1.useCallback)((count, direction) => {
        if (direction) {
            onChange({
                count,
                key: direction === sonar_ts_types_1.PaginationDirection.Forward ? resource === null || resource === void 0 ? void 0 : resource.nextKey : resource === null || resource === void 0 ? void 0 : resource.prevKey,
                paginationDirection: direction
            });
        }
        else {
            onChange({
                count,
                key: undefined,
                paginationDirection: sonar_ts_types_1.PaginationDirection.Forward
            });
        }
        setTimeout(() => {
            var _a;
            if (listRef === null || listRef === void 0 ? void 0 : listRef.current) {
                const element = (_a = listRef.current.nativeElement) !== null && _a !== void 0 ? _a : listRef.current;
                element.scrollTop = 0;
            }
        }, 250);
    }, [listRef, resource === null || resource === void 0 ? void 0 : resource.nextKey, resource === null || resource === void 0 ? void 0 : resource.prevKey, onChange]);
    const results = (_b = resource === null || resource === void 0 ? void 0 : resource.results) !== null && _b !== void 0 ? _b : [];
    if (!results.length) {
        return null;
    }
    return (react_1.default.createElement(SimplePagination_1.SimplePagination, Object.assign({}, rest, { enableNext: Boolean(resource === null || resource === void 0 ? void 0 : resource.nextKey), enablePrev: Boolean(resource === null || resource === void 0 ? void 0 : resource.prevKey), onChange: handlePaginationChange, showSizeChanger: showSizeChanger })));
};
exports.KeyPagination = KeyPagination;
