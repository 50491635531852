"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriscopePagination = exports.paginationFallbackQueryParams = exports.paginationDefaultQueryParams = void 0;
require("./PeriscopePagination.scss");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
exports.paginationDefaultQueryParams = {
    count: 20,
    offset: 0
};
exports.paginationFallbackQueryParams = {
    offset: 0
};
const PeriscopePagination = ({ loading, onChange, queryParams, scrollRef, totalCount }) => {
    var _a, _b;
    const handlePaginationChange = (0, react_1.useCallback)((page, pageSize) => {
        onChange({
            count: pageSize,
            offset: (page - 1) * pageSize
        });
        setTimeout(() => {
            if (scrollRef.current) {
                scrollRef.current.scrollTop = 0;
            }
        }, 250);
    }, [onChange, scrollRef]);
    if (loading) {
        return null;
    }
    const offset = (_a = queryParams.offset) !== null && _a !== void 0 ? _a : exports.paginationDefaultQueryParams.offset;
    const pageSize = (_b = queryParams.count) !== null && _b !== void 0 ? _b : exports.paginationDefaultQueryParams.count;
    const page = offset < pageSize ? 1 : Math.ceil((offset + 1) / pageSize);
    const hideOnSinglePage = totalCount <= pageSize;
    return (react_1.default.createElement("div", { className: 'periscope-field-list-paginator' },
        react_1.default.createElement(antd_1.Pagination, { current: page, hideOnSinglePage: hideOnSinglePage, onChange: handlePaginationChange, pageSize: pageSize, showSizeChanger: true, total: totalCount })));
};
exports.PeriscopePagination = PeriscopePagination;
